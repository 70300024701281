import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "enable", "simple", "partner", "child", "comment"];

  connect() {
    this.toggle();
  }

  toggle() {
    const readOnly = !this.enableTarget.checked;
    console.log(readOnly);
    this.simpleTarget.disabled = readOnly;
    this.partnerTarget.disabled = readOnly;
    this.childTarget.disabled = readOnly;
    this.commentTarget.disabled = readOnly;

    if (readOnly) {
      this.simpleTarget.checked = false;
      this.partnerTarget.checked = false;
      this.childTarget.checked = false;
    }
  }
}
