import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "name", "birthday", "persons", 'type', 'spouseBtn' ];
  count;
  spouse;

  connect() {
    this.count = 0;
    this.spouse = -1;
  }

  addChild(event) {
    event.preventDefault();
    this.add(1, 'Kind');
  }

  addSpouse(event) {
    event.preventDefault();
    this.spouse = this.count;
    this.add(0, 'Ehepartner');
    event.currentTarget.disabled = true;
  }

  add(value, label) {
    let wrapper = document.createElement("div");
    wrapper.setAttribute('id', 'person' + this.count);

    let type = document.createElement('input');
    type.setAttribute('type', 'hidden');
    type.setAttribute('name', 'event_registration' + this.count + '[type]');
    type.setAttribute('value', value);
    wrapper.appendChild(type);

    let header = document.createElement("h3");
    header.innerHTML = label;
    wrapper.appendChild(header);

    let clone = this.nameTarget.cloneNode( true );
    clone.setAttribute('data-target', '');
    let textField = clone.querySelector("#event_registration_name");
    textField.value = '';
    textField.setAttribute('id', 'event_registration_name' + this.count);
    textField.setAttribute('name', 'event_registration' + this.count + '[name]');
    wrapper.appendChild(clone);

    if (this.hasBirthdayTarget) {
      clone = this.birthdayTarget.cloneNode(true);
      clone.setAttribute('data-target', '');
      let i;
      for (i = 1; i <= 3; i++) {
        textField = clone.querySelector("#event_registration_birthday_" + i +"i");
        textField.setAttribute('id', 'event_registration_birthday' + this.count +'_' + i +'i');
        textField.setAttribute('name', 'event_registration' + this.count + '[birthday('+ i + 'i)]');
      }

      wrapper.appendChild(clone);
    }


    let remove = document.createElement("a");
    remove.innerHTML = label + ' löschen';
    remove.setAttribute('data-action', 'event-registration#remove');
    remove.setAttribute('href', '#');
    remove.setAttribute('class', 'btn btn-danger');
    wrapper.appendChild(remove);

    wrapper.appendChild(document.createElement("hr"));

    this.personsTarget.appendChild(wrapper);

    this.count++;
  }

  remove(event) {
    event.preventDefault();
    let parentElement = event.currentTarget.parentElement;
    if (this.spouse >= 0 && parentElement.id === 'person'+this.spouse ) {
      this.spouseBtnTarget.disabled = false;
      this.spouse = -1;
    }

    parentElement.remove();
  }
}
